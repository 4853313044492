import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../formJsonData/LMS/ExternalJrf.json";
import Form from "../../components/common/Form";
import { postDataFromApi } from "../../services/commonServices";
import { MasterListApi } from "../../services/api";

const ExternalJrf = () => {
  const [masterResponse, setMasterResponse] = useState([]);

  useEffect(() => {
    getLabMasterData();
  }, []);
  const getLabMasterData = async () => {
    try {
      let tempBody = {
        model_name: "lab",
        is_dropdown: true,
      };
      let res = await postDataFromApi(MasterListApi, tempBody);
      if (res?.data?.status === 200 && res.data.data) {
        const transformedData = res.data.data.map((labDetail) => ({
          id: labDetail[0],
          name: labDetail[2] ? labDetail[2] + ` (${labDetail[1]})` : "",
        }));

        const bodyToPass = {
          model: "jrf_lab",
          data: transformedData,
        };

        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Row>
      <Col>
        <Form
          formConfig={formConfig}
          masterResponse={masterResponse}
          isExternalJRF="1"
        />
      </Col>
    </Row>
  );
};
export default ExternalJrf;
