import React, { useEffect } from "react";
import InputText from "./elements/InputText";
import InputDoubleText from "./elements/InputDoubleText";
import InputTextArea from "./elements/InputTextArea";
import InputLabel from "./elements/InputLabel";
import InputDate from "./elements/InputDate";
import InputEmail from "./elements/InputEmail";
import InputMobile from "./elements/InputMobile";
import InputPassword from "./elements/InputPassword";
import InputRadio from "./elements/InputRadio";
import InputCheckbox from "./elements/InputCheckbox";
import InputSelect from "./elements/InputSelect";
import InputMultiSelect from "./elements/InputMultiSelect";
import InputNumber from "./elements/InputNumber";
import InputPhone from "./elements/InputPhone";
import InputNumberPlusMinus from "./elements/InputNumPlusMin";
import DateTimePicker from "./elements/InputDatetime";
import HorizontalLine from "./elements/horizontalLine";
import CustomInput from "./elements/CustomInput";
import CustomInput2 from "./elements/CustomInput2";
import InputSelectMultiwithCheckbox from "./elements/InputSelectMultiwithCheckbox";
import PropTypes from "prop-types";
import InputSelectwithSearch from "./elements/InputSelectwithSearch";
import { getFormatedDate } from "../../services/commonFunction";

const RenderFields = ({
  field,
  sectionIndex,
  fieldIndex,
  formData,
  handleFieldChange,
  formErrors,
  GAData,
  setGAData,
  showModalGA,
  setShowModalGA,
  masterOptions,
  viewOnly,
  customName,
  renderTable,
  actionClicked,
  from,
  centerAlign,
  isStaticValue,
  exludeOptions,
  handleFieldBlur,
  isViewLabel,
  upperClass,
  tooltipTrue,
  setFormData
}) => {
  let newFieldName = "";
  if (customName) {
    newFieldName = customName;
  } else {
    newFieldName = field.name;
  }

  let { type, validation, name, value } = field;
  let newFieldValue = "";
  if (type === "date") {
    newFieldValue = formData[sectionIndex]?.[newFieldName]
      ? getFormatedDate(formData[sectionIndex]?.[newFieldName], 1)
      : "";
  }

  type =
    viewOnly && !["doubleText", "textArea"].includes(type) ? "label" : type;
  type = name === "sa_sampleallottedto" ? "select" : type;
  type = [
    "jrf_vc_term_condition",
    "jrf_terms_and_conditions",
    "non_scope",
  ].includes(name)
    ? "checkbox"
    : type;
  type = name == "ic_smpldrawnbylab" ? "radio" : type;
  field.label = from === "Table" ? "" : field.label;
  if (field.name === "smpl_detail_recpt_mode" && field.readOnly) {
    type = "label";
  }
  useEffect(() => {
    if (viewOnly) {
      if (!value) {
        value = "--";
      }
    }
  }, []);
  field.centerAlign = centerAlign;
  const showError = formErrors?.[sectionIndex]?.[fieldIndex];
  const getLabelValue = () => { };
  switch (type) {
    case "text":
      return (
        <InputText
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            handleFieldBlur: handleFieldBlur
              ? (e) =>
                handleFieldBlur(sectionIndex, newFieldName, e.target.value)
              : null,
            required: field.required,
            errorMessage: showError ? validation.message : "",
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: tooltipTrue ? value : field.tooltip,
            characterLimit: field.characterLimit,
            validation: validation,
            icon: field.icon,
            styleName: field.styleName,
            fieldWidth: field.fieldWidth,
            pattern: field.pattern,
            renderTable: renderTable,
            actionClicked: actionClicked,
            upperClass: upperClass,
            from: from,
            isPatternMessage: field.isPatternMessage,
          }}
        />
      );
    case "doubleText":
      return (
        <InputDoubleText
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange1: (e, isValue) => {
              handleFieldChange(sectionIndex, newFieldName, isValue ? e : e.target.value);
            },
            onChange2: (e) => {
              handleFieldChange(sectionIndex, field.secondName, e.target.value);
            },
            onChange3: (e) => {
              handleFieldChange(
                sectionIndex,
                field.thirdName,
                e.target.checked
              );
            },
            onBlur1: (e) =>
              handleFieldBlur
                ? handleFieldBlur(sectionIndex, newFieldName, e.target.value)
                : null,
            onBlur2: (e) =>
              handleFieldBlur
                ? handleFieldBlur(
                  sectionIndex,
                  field.secondName,
                  e.target.value
                )
                : null,
            required: field.required,
            errorMessage: showError ? validation.message : "",
            placeholder: field.placeholder,
            readOnly: field.readOnly || viewOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            validation: validation,
            icon: field.icon,
            fieldWidth: field.fieldWidth,
            pattern: field.pattern,
            renderTable: renderTable,
            actionClicked: actionClicked,
            secondName: field.secondName,
            secondReadOnly: field.secondReadOnly,
            restrictByCheckbox:field.restrictByCheckbox,
            isShowRadioBefore: field.isShowRadioBefore,
            setFormData:setFormData,
            secondValue: field.secondValue
              ? field.secondValue
              : formData[sectionIndex]?.[field.secondName] || "",
            thirdValue: field.thirdValue
              ? field.thirdValue
              : formData[sectionIndex]?.[field.thirdName] || "",
            thirdName: field.thirdName,
            firstType: field.firstType,
            secondType: field.secondType,
            secondoptions: field.secondoptions,
            thirdType: field.thirdType,
            viewOnly: viewOnly,
          }}
          actualField={field}
          sectionIndex={sectionIndex}
          fieldIndex={fieldIndex}
          formData={formData}
          handleFieldChange={handleFieldChange}
          formErrors={formErrors}
        />
      );
    case "textArea":
      return (
        <InputTextArea
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "",
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            validation: validation,
            icon: field.icon,
            fieldWidth: field.fieldWidth,
            pattern: field.pattern,
            actionClicked: actionClicked,
            viewOnly: viewOnly,
          }}
        />
      );
    case "label":
      return (
        <InputLabel
          key={newFieldName}
          field={{
            fontSize: field.fontSize,
            headerLength: field.headerLength,
            textDecoration: field.textDecoration,
            color: field.color,
            name: newFieldName,
            label: field.label,
            value: !isStaticValue
              ? newFieldValue
                ? newFieldValue
                : formData[sectionIndex]?.[newFieldName] ||
                  formData[sectionIndex]?.[newFieldName] === 0
                  ? formData[sectionIndex]?.[newFieldName]
                  : field.value
              : field.value,
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "",
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: tooltipTrue
              ? !isStaticValue
                ? newFieldValue
                  ? newFieldValue
                  : formData[sectionIndex]?.[newFieldName] || field.value
                : field.value
              : field.tooltip,
            characterLimit: field.characterLimit,
            validation: validation,
            icon: field.icon,
            fieldWidth: field.fieldWidth,
            styleName: field.styleName,
            actionClicked: actionClicked,
            centerAlign: centerAlign,
            upperClass: upperClass,
          }}
        />
      );
    case "number":
      return (
        <InputNumber
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputNumber component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: tooltipTrue
              ? !isStaticValue
                ? newFieldValue
                  ? newFieldValue
                  : formData[sectionIndex]?.[newFieldName] || field.value
                : field.value
              : field.tooltip,
            characterLimit: field.characterLimit,
            fieldWidth: field.fieldWidth,
            minValue: field.minValue,
            maxValue: field.maxValue,
            pattern: field.pattern,
            actionClicked: actionClicked,
            styleName: field.styleName,
            handleFieldBlur: handleFieldBlur
              ? (e) =>
                handleFieldBlur(sectionIndex, newFieldName, e.target.value)
              : null,
            upperClass: upperClass,
          }}
        />
      );

    case "numberPlusMinus":
      return (
        <InputNumberPlusMinus
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputNumber component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            fieldWidth: field.fieldWidth,
            actionClicked: actionClicked,
          }}
        />
      );

    case "select":
      return (
        <React.Fragment>
          {field.GA ? (
            <InputMultiSelect
              key={newFieldName}
              field={{
                name: newFieldName,
                label: field.label,
                value: formData[sectionIndex]?.[newFieldName] || "",
                onChange: (e) =>
                  handleFieldChange(sectionIndex, newFieldName, e.target.value),
                required: field.required,
                errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
                options: field.options,
                fieldWidth: field.fieldWidth,
                multiple: field.multiple,
                placeholder: field.placeholder,
                GAData: GAData,
                setGAData: setGAData,
                showModalGA: showModalGA,
                setShowModalGA: setShowModalGA,
                actionClicked: actionClicked,
                upperClass: upperClass,
              }}
            />
          ) : field.multiple ? (
            <InputSelectMultiwithCheckbox
              key={newFieldName}
              field={{
                name: newFieldName,
                customname: field.name,
                label: field.label,
                value: formData[sectionIndex]?.[newFieldName] || "",

                onChange: (e) => {
                  field.multiple
                    ? handleFieldChange(sectionIndex, newFieldName, e)
                    : handleFieldChange(
                      sectionIndex,
                      newFieldName,
                      e.target.value
                    );
                },
                required: field.required,
                errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
                options: field.options,
                masterOptions: masterOptions,

                fieldWidth: field.fieldWidth,
                multiple: field.multiple,
                placeholder: field.placeholder,
                actionClicked: actionClicked,
                specialClass: field.specialClass,
                from: from,
                isCustomOptions: field.isCustomOptions,
                customOptions: field.customOptions,
                exludeOptions: exludeOptions,
                labelWidth: field.labelWidth,
                hintText: field.hintText,
              }}
            />
          ) : field.isSearchable ? (
            <InputSelectwithSearch
              key={newFieldName}
              field={{
                name: newFieldName,
                customname: field.name,
                label: field.label,
                value: formData[sectionIndex]?.[newFieldName] || "",

                onChange: (e) => {
                  handleFieldChange(sectionIndex, newFieldName, e.value);
                },
                required: field.required,
                errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
                options: field.options,
                masterOptions: masterOptions,

                fieldWidth: field.fieldWidth,
                multiple: field.multiple,
                placeholder: field.placeholder,
                actionClicked: actionClicked,
                specialClass: field.specialClass,
                from: from,
                viewOnly: viewOnly,
                isCustomOptions: field.isCustomOptions,
                customOptions: field.customOptions,
                exludeOptions: exludeOptions,
                isSearchable: field.isSearchable,
              }}
            />
          ) : (
            <InputSelect
              key={newFieldName}
              field={{
                name: newFieldName,
                customname: field.name,
                label: field.label,
                value: formData[sectionIndex]?.[newFieldName] || "",

                onChange: (e, isValue) => {
                  field.multiple
                    ? handleFieldChange(sectionIndex, newFieldName, isValue ? e : e)
                    : handleFieldChange(
                      sectionIndex,
                      newFieldName,
                      isValue ? e : e.target.value
                    );
                },
                handleFieldBlur: handleFieldBlur
                  ? (e) => {
                    field.multiple
                      ? handleFieldBlur(sectionIndex, newFieldName, e)
                      : handleFieldBlur(
                        sectionIndex,
                        newFieldName,
                        e.target.value
                      );
                  }
                  : null,
                required: field.required,
                errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
                options: field.options,
                masterOptions: masterOptions,

                fieldWidth: field.fieldWidth,
                multiple: field.multiple,
                placeholder: field.placeholder,
                actionClicked: actionClicked,
                specialClass: field.specialClass,
                from: from,
                viewOnly: viewOnly,
                isCustomOptions: field.isCustomOptions,
                customOptions: field.customOptions,
                exludeOptions: exludeOptions,
                isSearchable: field.isSearchable,
                upperClass: upperClass,
                readOnly: field.readOnly,
              }}
            />
          )}
        </React.Fragment>
      );

    case "radio":
      return (
        <InputRadio
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",

            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            tooltip: field.tooltip,
            fieldWidth: field.fieldWidth,
            options: field.options,
            actionClicked: actionClicked,
            viewOnly: viewOnly,
          }}
        />
      );
    case "phone":
      return (
        <InputPhone
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            required: field.required,
            errorMessage: showError ? validation.message : "",
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            fieldWidth: field.fieldWidth,
            minValue: field.minValue,
            maxValue: field.maxValue,
            pattern: field.pattern,
            actionClicked: actionClicked,
            styleName: field.styleName,
            handleFieldBlur: handleFieldBlur
              ? (e) =>
                handleFieldBlur(sectionIndex, newFieldName, e.target.value)
              : null,
            upperClass: upperClass,
            onChange: (value) => {
              handleFieldChange(sectionIndex, newFieldName, value);
            },
          }}
        />
      );
    case "checkbox":
      return (
        <span>
          <InputCheckbox
            key={newFieldName}
            field={{
              name: newFieldName,
              label: field.label,
              value: formData[sectionIndex]?.[newFieldName] || "",
              onChange: (e, options) =>
                handleFieldChange(
                  sectionIndex,
                  newFieldName,
                  [
                    "jrf_terms_and_conditions",
                    "jrf_vc_term_condition",
                  ].includes(newFieldName)
                    ? e?.target?.checked
                    : options,
                  [
                    "jrf_terms_and_conditions",
                    "jrf_vc_term_condition",
                  ].includes(newFieldName)
                    ? e?.target?.type
                    : null,

                  e?.target?.checked
                ),
              required: field.required,
              errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
              tooltip: field.tooltip,
              options: field.options,
              viewOnly: viewOnly,
              actionClicked: actionClicked,
              isOptionLabelNotShow: field.isOptionLabelNotShow,
            }}
          />
        </span>
      );

    case "password":
      return (
        <InputPassword
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            actionClicked: actionClicked,
          }}
        />
      );
    case "email":
      return (
        <InputEmail
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            actionClicked: actionClicked,
          }}
        />
      );
    case "tel":
      return (
        <InputMobile
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            pattern: field.pattern,
            actionClicked: actionClicked,
          }}
        />
      );
    case "date":
      return (
        <InputDate
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            minDate: field.minDate,
            maxDate: field.maxDate,
            defaultValue: formData[sectionIndex]?.[newFieldName] || "",
            fieldWidth: field.fieldWidth,
            pastDate: field.pastDate,
            pastdays: field.pastdays,
            futureDays: field.futureDays,
            renderTable: renderTable,
            actionClicked: actionClicked,
            upperClass: upperClass,
            startDate: field.startDate,
            noLimitation: field.noLimitation,
          }}
        />
      );
    case "datetime":
      return (
        <DateTimePicker
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex]?.[newFieldName] || "",
            onChange: (e) =>
              handleFieldChange(sectionIndex, newFieldName, e.target.value),
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputSelect component
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            minDate: field.minDate,
            maxDate: field.maxDate,
            placeholder: field.placeholder,
            actionClicked: actionClicked,
          }}
        />
      );

    case "hr":
      return <HorizontalLine />;
    case "customInput":
      return (
        <CustomInput
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex],
            onChange: handleFieldChange,
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputNumber component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            fieldWidth: field.fieldWidth,
            minValue: field.minValue,
            maxValue: field.maxValue,
            pattern: field.pattern,
            sectionIndex: sectionIndex,
            actionClicked: actionClicked,
          }}
        />
      );

    case "customInput2":
      return (
        <CustomInput2
          key={newFieldName}
          field={{
            name: newFieldName,
            label: field.label,
            value: formData[sectionIndex],
            onChange: handleFieldChange,
            required: field.required,
            errorMessage: showError ? validation.message : "", // Pass error message to InputNumber component
            placeholder: field.placeholder,
            readOnly: field.readOnly,
            tooltip: field.tooltip,
            characterLimit: field.characterLimit,
            fieldWidth: field.fieldWidth,
            minValue: field.minValue,
            maxValue: field.maxValue,
            pattern: field.pattern,
            sectionIndex: sectionIndex,
            actionClicked: actionClicked,
          }}
        />
      );
    default:
      return null;
  }
};

RenderFields.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    icon: PropTypes.element,
    fieldWidth: PropTypes.string,
    pattern: PropTypes.string,
    renderTable: PropTypes.bool,
    errorMsgs: PropTypes.object,
    actionClicked: PropTypes.func,
    isShowRadioBefore: PropTypes.bool,
    thirdName: PropTypes.string,
    thirdValue: PropTypes.any,
    firstType: PropTypes.string,
    secondType: PropTypes.string,
    thirdType: PropTypes.string,
    secondoptions: PropTypes.arrayOf(PropTypes.object),
    onBlur2: PropTypes.func,
    onBlur1: PropTypes.func,
    viewOnly: PropTypes.bool,
    startDate: PropTypes.string, // Assuming date is a string
    validation: PropTypes.shape({
      message: PropTypes.string,
    }),
    minDate: PropTypes.string, // Assuming date is a string
    maxDate: PropTypes.string, // Assuming date is a string
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.object),
    styleName: PropTypes.string,
    isOptionLabelNotShow: PropTypes.bool,
    pastDate: PropTypes.string, // Assuming date is a string
    pastdays: PropTypes.number,
    futureDays: PropTypes.number,
    type: PropTypes.string, // Added this
    centerAlign: PropTypes.bool, // Added this
    secondName: PropTypes.string, // Added this
    secondReadOnly: PropTypes.bool, // Added this
    secondValue: PropTypes.any, // Added this
    fontSize: PropTypes.string, // Added this
    headerLength: PropTypes.number, // Added this
    textDecoration: PropTypes.string, // Added this
    color: PropTypes.string, // Added this
    GA: PropTypes.any, // Added this, assuming any type
    multiple: PropTypes.bool, // Added this
    specialClass: PropTypes.string, // Added this
    isCustomOptions: PropTypes.bool, // Added this
    customOptions: PropTypes.arrayOf(PropTypes.object), // Added this
    labelWidth: PropTypes.string, // Added this
    isSearchable: PropTypes.bool, // Added this
  }),
  sectionIndex: PropTypes.number,
  fieldIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  formErrors: PropTypes.object,
  GAData: PropTypes.arrayOf(PropTypes.object),
  setGAData: PropTypes.func,
  showModalGA: PropTypes.bool,
  setShowModalGA: PropTypes.func,
  masterOptions: PropTypes.arrayOf(PropTypes.object),
  viewOnly: PropTypes.bool,
  customName: PropTypes.string,
  renderTable: PropTypes.bool,
  actionClicked: PropTypes.func,
  from: PropTypes.string,
  centerAlign: PropTypes.bool,
  isStaticValue: PropTypes.bool,
  exludeOptions: PropTypes.arrayOf(PropTypes.object),
  handleFieldBlur: PropTypes.func,
  isViewLabel: PropTypes.bool,
  upperClass: PropTypes.string,
};

export default RenderFields;
